import React from "react";
import modera from "../../Assets/Images/modera.png";
import { useTranslation } from "react-i18next";
function Form() {
  const { t } = useTranslation();

  return (
    <div className="flex flex-col items-center lg:flex-row gap-5 lg:gap-[5rem] justify-between  shadow-custom px-[3rem] lg:pe-0 lg:ps-20 mt-16 mb-10  w-full">
      <form>
        <div className="flex flex-col items-start">
          <label
            htmlFor=""
            className="text-[#141414] text-start text-[18px] lg:text-[20px] font-[Cairo] font-[600] py-3"
          >
            {t("Name")}
          </label>
          <input
            type="text"
            placeholder={t("Enter Your Name")}
            className="shadow-custom border-[1px] h-[40px]  w-[60vw] lg:w-[600px] lg:h-[60px] px-5"
          />
        </div>
        <div className="flex flex-col items-start">
          <label
            htmlFor=""
            className="text-[#141414] text-start text-[18px] lg:text-[20px] font-[Cairo] font-[600] py-3"
          >
            {t("Email")}
          </label>
          <input
            type="text"
            placeholder={t("Enter Your Email")}
            className="shadow-custom border-[1px] h-[40px]  w-[60vw] lg:w-[600px] lg:h-[60px] px-5"
          />
        </div>
        <div className="flex flex-col items-start">
          <label
            htmlFor=""
            className="text-[#141414] text-start text-[18px] lg:text-[20px] font-[Cairo] font-[600] py-3"
          >
            {t("Phone")}
          </label>
          <input
            type="text"
            placeholder={t("EnterYourPhone")}
            className="shadow-custom border-[1px] h-[40px]  w-[60vw] lg:w-[600px] lg:h-[60px] px-5"
          />
        </div>
        <div className="flex flex-col items-start">
          <label
            htmlFor=""
            className="text-[#141414] text-start text-[18px] lg:text-[20px] font-[Cairo] font-[600] py-3"
          >
            {t("Message")}
          </label>
          <textarea
            placeholder={t("EnterYourMessage")}
            className="shadow-custom border-[1px] w-[60vw] lg:w-[600px] h-[140px] px-5 py-3"
          />
        </div>
        <button className="bg-[#0A66B0] text-[#FFF] text-[16px] lg:text-[20px] font-[Cairo] font-[400] block mx-auto mt-14 mb-5 py-1 px-16 lg:px-32 rounded-full">
          {t("Send")}
        </button>
      </form>
      {/* h-[90vh] w-[33vw] */}
      <img
        src={modera}
        alt=""
        className="h-[40vh] lg:h-[800px] lg:w-[33vw] w-[500px] xl:w-[30vw]"
      />
    </div>
  );
}

export default Form;
