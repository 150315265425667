import axios from "axios";
import { createContext, useContext, useEffect, useState } from "react";
import { newBaseUrl } from "../Consts"
export const NewsContext = createContext();
export const useNews = () => {
    return useContext(NewsContext);
};

export default function NewsContextProvider({ children }) {
    const [isNews, setIsNews] = useState();
    const [isNewsCategories, setIsNewsCategories] = useState();
    const [NewsContent, setNewsContent] = useState();
    const [isNewsDetails, setIsNewsDetails] = useState();
    const storedLanguage = localStorage.getItem('language');
    const initialLanguage = storedLanguage || 'en';
    const headers = {
        'Content-Type': 'application/json',
        'Content-Language': initialLanguage
    };

    // useEffect(() => {
    //     getNews();
    //     getNewsCategory();
    //     getNewsContent();
    // }, []);

    async function getNews() {
        try {
            const res = await axios.get(`${newBaseUrl}/api/news`, { headers });
            setIsNews(res?.data)
            // console.log(res?.data?.data);
        } catch (err) {
            console.error(err);
        }
    }

    async function getNewsDetails(id) {
        try {
            const res = await axios.get(`${newBaseUrl}/api/news/${id}`, { headers });
            // console.log(res?.data?.data);
            setIsNewsDetails(res?.data)
        } catch (err) {
            console.error(err);
        }
    }

    async function getNewsCategories() {
        try {
            const res = await axios.get(`${newBaseUrl}/api/news-category`);
            setIsNewsCategories(res?.data)
            // console.log(res?.data?.data);
        } catch (err) {
            console.error(err);
        }
    }

    return (
        <NewsContext.Provider
            value={{
                getNews,
                getNewsCategories,
                getNewsDetails,
                isNews,
                isNewsCategories,
                isNewsDetails
            }}
        >
            {children}
        </NewsContext.Provider>
    );
}
