import { Navigate, RouterProvider, createBrowserRouter } from 'react-router-dom';
import './App.css';
import Home from './Pages/Home';
import AboutUs from './Pages/AboutUs';
import Services from './Pages/Services';
import Service from './Pages/Service';
import Projects from './Pages/Projects';
import Project from './Pages/Project';
import Products from './Pages/Products';
import Product from './Pages/Product';
import News from './Pages/News';
import New from './Pages/New';
import Careers from './Pages/Careers';
import Career from './Pages/Career';
import Contact from './Pages/Contact';
import Order from './Pages/Order';
import Layout from './Layout/Layout';
import AllProducts from './Pages/AllProducts';
import AllBrands from './Pages/AllBrands';
import ApplyNow from './Pages/ApplyNow';
import ProductsContextProvider from './Context/ProductsContext';
import ServicesContextProvider from './Context/ServicesContext';
import NewsContextProvider from './Context/NewsContext';
import ProjectsContextProvider from './Context/ProjectsContext';
import "./i18next";
import HomeContextProvider from './Context/HomeContext';
import AboutContextProvider from './Context/AboutContext';
import { useEffect, useState } from 'react';
import CareersContextProvider from './Context/CareersContext';

function App() {

  const routers = createBrowserRouter([
    {
      path: "/",
      element: <Layout />,
      children: [
        { path: '', index: true, element: <Navigate to={'home'} /> },
        {
          path: "home",
          element: <Home />,

        },
        {
          path: "about",
          element: <AboutUs />,
        },
        {
          path: "services",
          element: <Services />,
        },
        {
          path: "service/:id",
          element: <Service />,
          // loader: teamLoader,
        },
        {
          path: "projects",
          element: <Projects />,
        },
        {
          path: "project/:id",
          element: <Project />,
        },
        {
          path: "products",
          element: <Products />,
        },
        {
          path: "product/:id",
          element: <Product />,
        },
        {
          path: "news",
          element: <News />,
        },
        {
          path: "news/:id",
          element: <New />,
        },
        {
          path: "careers",
          element: <Careers />,
        },
        {
          path: "career/:id",
          element: <Career />,
        },
        {
          path: "contact",
          element: <Contact />,
        },
        {
          path: "order",
          element: <Order />,
        },
        {
          path: "allProducts",
          element: <AllProducts />,
        },
        {
          path: "brand/:id",
          element: <AllBrands />,
        },
        {
          path: "applyNow/:title",
          element: <ApplyNow />,
        },
      ],
    },
  ]);

  return (
    <div className="App">
      <ServicesContextProvider>
        <ProductsContextProvider>
          <NewsContextProvider>
            <ProjectsContextProvider>
              <HomeContextProvider>
                <AboutContextProvider>
                  <CareersContextProvider>
                    <RouterProvider router={routers}></RouterProvider>
                  </CareersContextProvider>
                </AboutContextProvider>
              </HomeContextProvider>
            </ProjectsContextProvider>
          </NewsContextProvider>
        </ProductsContextProvider>
      </ServicesContextProvider>
    </div>

  );
}

export default App;
