import axios from "axios";
import { createContext, useContext, useState } from "react";
import { initialLanguage, newBaseUrl } from "../Consts"

export const HomeContext = createContext()
export const useHome = () => {
    return useContext(HomeContext)
}

export default function HomeContextProvider({ children }) {


    const [isHome, setIsHome] = useState()
    const [isContact, setIsContact] = useState()

    async function getHome() {
        const headers = {
            'Content-Type': 'application/json',
            'Content-Language': initialLanguage
        };
        try {
            const res = await axios.get(`${newBaseUrl}/api/home`, { headers });
            // console.log(res?.data?.data);
            setIsHome(res?.data)
        } catch (err) {
            console.error(err);
        }
    }

    async function getContact() {
        const headers = {
            'Content-Type': 'application/json',
            'Content-Language': initialLanguage
        };
        try {
            const res = await axios.get(`${newBaseUrl}/api/contacts`, { headers });
            // console.log(res?.data?.data);
            setIsContact(res?.data)
        } catch (err) {
            console.error(err);
        }
    }

    return <HomeContext.Provider value={{
        getHome, isHome, getContact, isContact
    }}>
        {children}
    </HomeContext.Provider>
}
