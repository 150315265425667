import React, { useEffect } from "react";
import pr1 from "../../Assets/Images/cate.png";
import pr2 from "../../Assets/Images/cate1.png";
import pr3 from "../../Assets/Images/cate2.png";
import { Link } from "react-router-dom";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { useProducts } from "../../Context/ProductsContext";
import { useTranslation } from "react-i18next";

function SectionFive() {
  const { getProductsCategory, isProductsCategory } = useProducts();
  const { t } = useTranslation();

  useEffect(() => {
    getProductsCategory();
  }, []);

  const settings = {
    dots: false,
    autoplay: true,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    arrows: false,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: true,
          dots: false,
          autoplay: true,
          speed: 500,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 1,
          dots: false,
          autoplay: true,
          infinite: true,
          speed: 500,
        },
      },
    ],
  };

  return (
    <div className="flex flex-col gap-5 w-full">
      <div className="text-start">
        <h1 className="text-[#0A66B0] text-[22px] lg:text-[44px] font-[Cairo] font-[600]">
          {t("Categories")}
        </h1>
      </div>
      <Slider {...settings}>
        {isProductsCategory?.data?.map((product) => (
          <Link to={"/allProducts"} key={product.id}>
            <div className="flex flex-col gap-5 items-center cursor-pointer">
              <img
                src={product.image_url}
                alt=""
                className="w-full h-[350px] lg:w-[400px] lg:h-[400px]"
              />
              <h3 className="text-[#141414] text-[20px] lg:text-[32px] font-[Cairo] font-[400]">
                {product.name}
              </h3>
            </div>
          </Link>
        ))}
      </Slider>
    </div>
  );
}

export default SectionFive;
