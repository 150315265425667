import React from "react";
import { Link } from "react-router-dom";
import fb from "../../Assets/Images/Frame.svg";
import ig from "../../Assets/Images/Frame (1).svg";
import li from "../../Assets/Images/Frame (2).svg";
import yt from "../../Assets/Images/Frame (3).svg";
import wu from "../../Assets/Images/Frame (4).svg";
import { useTranslation } from "react-i18next";

function Footer() {
  const { t } = useTranslation();
  const currentYear = new Date().getFullYear();
  const footerData = [
    { name: t("Careers"), link: "/careers" },
    { name: t("Contact Us"), link: "/contact" },
    { images: [fb, ig, li, yt, wu] },
    { name: t("Terms & Conditions") },
    { name: t("Privacy Policy") },
  ];

  return (
    <div className="px-[2rem] lg:px-[6rem] py-16 flex flex-col justify-center">
      <div className="border-t-[1px] border-[#0A66B0] py-5 flex flex-col items-center justify-between lg:flex-wrap lg:justify-around gap-5 lg:gap-0 lg:flex-row md:flex-row  text-[#0A66B0] text-[15px] lg:text-[18px] font-[Cairo] font-[700] leading-[120%] lg:border-0">
        {footerData?.map((item, index) => (
          <Link key={index} to={item.link}>
            <div className="flex flex-row gap-[10px] lg:gap-[19px] lg:ms-[6rem]">
              {item.images &&
                item.images.map((image, imageIndex) => (
                  <img
                    key={imageIndex}
                    src={image}
                    alt=""
                    className=" w-[1.3rem] h-[1.3rem]"
                  />
                ))}
              {item.name && <h1>{item.name}</h1>}
            </div>
          </Link>
        ))}
      </div>
      <div className="text-[#0A66B0] text-[10px] lg:text-[16px] font-[Cairo] font-[600] leading-[120%]">
        <h3>
          © {currentYear} {t("Arch Sense Group. All right reserved.")}
          <br /> Designed by <span className="underline">Codescreed </span>
        </h3>
      </div>
    </div>
  );
}

export default Footer;
