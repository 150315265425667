import React from "react";
import banner from "../../Assets/Images/about-banner.jpeg";
import banner1 from "../../Assets/Images/partners.png";

function Banner() {
  return (
    <div className="relative w-full h-[300px] mt-[9rem]">
      <img src={banner} alt="" className="w-full h-full object-cover" />
      <div className="absolute inset-0 bg-[#00000040]"></div>
      <div
        data-aos="fade-right"
        data-aos-delay="1000"
        className="about-title absolute top-0 lg:top-[2rem] bottom-0 left-14"
      >
        <img
          src={banner1}
          alt=""
          className="w-[65vw] h-[40vh] lg:w-full lg:h-[30vh]"
        />
      </div>
    </div>
  );
}

export default Banner;
