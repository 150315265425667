import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";

function SectionOne({ isProduct }) {
  const [mainImage, setMainImage] = useState("");
  const [galleryImages, setGalleryImages] = useState([]);
  const [sliderOpen, setSliderOpen] = useState(false);
  const [selectedImageIndex, setSelectedImageIndex] = useState(0);

  useEffect(() => {
    if (isProduct) {
      if (isProduct.gallery && isProduct.gallery.length > 0) {
        setMainImage(isProduct.gallery[0].image_url);
        setGalleryImages(isProduct.gallery);
      } else {
        setMainImage(isProduct.image_url);
        setGalleryImages([]);
      }
    }
  }, [isProduct]);

  const handleThumbnailClick = (index) => {
    setMainImage(index);
  };

  const handleCloseSlider = () => {
    setSliderOpen(false);
  };

  const handleNextImage = () => {
    if (selectedImageIndex < galleryImages.length - 1) {
      setSelectedImageIndex(selectedImageIndex + 1);
    }
  };

  const handlePrevImage = () => {
    if (selectedImageIndex > 0) {
      setSelectedImageIndex(selectedImageIndex - 1);
    }
  };

  return (
    <div className="flex flex-col lg:flex-row mt-[10rem] mb-[5rem] gap-16 w-full">
      <div
        data-aos="fade-in"
        data-aos-delay="500"
        className="flex flex-col items-center gap-7"
      >
        <img
          src={mainImage}
          alt=""
          className="w-full h-[350px] lg:w-[500px] lg:h-[480px] cursor-pointer"
          onClick={() => setSliderOpen(true)}
        />
        <div className="flex flex-row items-center gap-5">
          {galleryImages.map((image, index) => (
            <img
              key={index}
              src={image.image_url}
              alt=""
              className="w-[125px] lg:w-[150px] h-[100px] lg:h-[150px] cursor-pointer"
              onClick={() => handleThumbnailClick(image.image_url)}
            />
          ))}
        </div>
      </div>
      <div
        data-aos="fade-left"
        data-aos-delay="500"
        className="flex flex-col text-start gap-3 lg:w-[48vw]"
      >
        <h1 className="text-[#0A66B0] text-[18px] lg:text-[40px] font-[Cairo] font-[700]">
          {isProduct?.title}
        </h1>
        <p className="text-[#141414] text-[16px] lg:text-[24px] font-[Cairo] font-[400]">
          {isProduct?.description}
        </p>
        <span className="border"></span>
        <p className="text-[#141414] text-[10px] lg:text-[20px] font-[Cairo] font-[400]">
          <span className="text-[#5F5F5F]">Catalog : </span>
          {isProduct?.category?.name}
        </p>
        <p className="text-[#141414] text-[10px] lg:text-[20px] font-[Cairo] font-[400]">
          <span className="text-[#5F5F5F]">Sub Category : </span>
          {isProduct?.subcategory?.name}
        </p>
        <p className="text-[#141414] text-[10px] lg:text-[20px] font-[Cairo] font-[400]">
          <span className="text-[#5F5F5F]">Brand : </span>
          {isProduct?.brand?.name}
        </p>
        <p className="text-[#141414] text-[10px] lg:text-[20px] font-[Cairo] font-[400]">
          <span className="text-[#5F5F5F]">Sub Brand : </span>{" "}
          {isProduct?.subbrand?.name}
        </p>
        <p className="text-[#141414] text-[10px] lg:text-[20px] font-[Cairo] font-[400]">
          <span className="text-[#5F5F5F]">SKU :</span> {isProduct?.sku}
        </p>
        <Link to={"/order"}>
          <button className="bg-[#0A66B0] text-[#FFF] text-[10px] lg:text-[20px] font-[Cairo] font-[400] block me-auto py-2 px-8 rounded-full">
            Order Now
          </button>
        </Link>
      </div>
      {sliderOpen && (
        <div className="fixed inset-0 bg-black bg-opacity-80 z-50 flex justify-center items-center">
          <div className="relative">
            <span
              className="absolute top-2 right-2 text-white cursor-pointer"
              onClick={handleCloseSlider}
            >
              &#10005;
            </span>
            {selectedImageIndex > 0 && (
              <span
                className="absolute top-1/2 transform -translate-y-1/2 left-2 text-white cursor-pointer"
                onClick={handlePrevImage}
              >
                &#10094;
              </span>
            )}
            {selectedImageIndex < galleryImages.length - 1 && (
              <span
                className="absolute top-1/2 transform -translate-y-1/2 right-2 text-white cursor-pointer"
                onClick={handleNextImage}
              >
                &#10095;
              </span>
            )}
            <img
              src={galleryImages[selectedImageIndex].image_url}
              alt=""
              className="max-h-[90vh] mx-auto"
            />
          </div>
        </div>
      )}
    </div>
  );
}

export default SectionOne;
