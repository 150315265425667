import React, { useEffect } from "react";
import banner from "../../Assets/Images/banner3.png";
import o from "../../Assets/Images/1 (1).svg";
import tw from "../../Assets/Images/1 (2).svg";
import th from "../../Assets/Images/1 (3).svg";
import fo from "../../Assets/Images/1 (4).svg";
import fi from "../../Assets/Images/1 (5).svg";
import AOS from "aos";
import "aos/dist/aos.css"; // Import the AOS styles
import { useTranslation } from "react-i18next";

function Banner() {
  const { t } = useTranslation();

  const containerStyle = {
    boxShadow: "0px 4px 4px 0px rgba(0, 0, 0, 0.25)",
    backgroundImage: `linear-gradient(270deg, rgba(0, 0, 0, 0.60) 9.81%, rgba(0, 0, 0, 0.00) 29.72%), url(${banner})`,
    backgroundSize: "cover",
    backgroundPosition: "center",
    backgroundRepeat: "no-repeat",
  };

  useEffect(() => {
    AOS.init({
      duration: 1000, // Animation duration in milliseconds
      once: true, // Only animate once
      easing: "ease", // Use a predefined easing function
    });
  }, []);

  return (
    <div style={containerStyle} className="relative h-[200px] lg:h-[400px] mt-[9rem] w-full lg:w-full ">
      <div className="absolute inset-0 bg-[#000000, #00000000]"></div>
      <div className="about-title absolute top-0 left-[1rem] lg:left-[5rem] lg:right-[3rem] right-[1rem] bottom-0 flex justify-between items-center">
        <h1
          data-aos="fade-up"
          className="text-[#fff] text-[36px] lg:text-[96px] drop-shadow-text font-[700] font-[Cairo]"
        >
          {t("Our Products")}
        </h1>
        <div className="flex flex-col gap-4 items-center">
          <span data-aos="fade-left">
            <img src={o} alt="" className="w-[10px] lg:w-[20px]" />
          </span>
          <span data-aos="fade-left">
            <img src={tw} alt="" className="w-[16px] lg:w-[24px]" />
          </span>
          <span data-aos="fade-left">
            <img src={th} alt="" className="w-[16px] lg:w-[24px]" />
          </span>
          <span data-aos="fade-left">
            <img src={fo} alt="" className="w-[16px] lg:w-[24px]" />
          </span>
          <span data-aos="fade-left">
            <img src={fi} alt="" className="w-[16px] lg:w-[24px]" />
          </span>
        </div>
      </div>
    </div>
  );
}

export default Banner;
