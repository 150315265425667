import React from "react";
import career from "../../Assets/Images/careers.jpg";
import { useTranslation } from "react-i18next";

function SectionOne() {
  const { t } = useTranslation();
  return (
    <div className="flex flex-col lg:flex-row items-center gap-10 my-10 w-full">
      <div
        data-aos="fade-right"
        data-aos-delay="1500"
        className="flex flex-col items-start"
      >
        <p className="text-start text-[#141414] text-[16px] lg:text-[24px] font-[Cairo] font-[400]">
          Alienum phaedrum torquatos nec eu, vis detraxit periculis ex, nihil
          expetendis in mei. Mei an pericula euripidis, hinc partem ei est. Eos
          ei nisl graecis, vix aperiri consequat an. Eius lorem tincidunt vix
          at, vel pertinax sensibus id, error epicurei.
        </p>
        <button className="bg-[#0A66B0] text-[#FFF] text-[14px] lg:text-[20px] font-[Cairo] font-[400] block me-auto mt-10  py-2 px-8 rounded-full">
          {t("SendYourCV")}
        </button>
      </div>
      <div data-aos="fade-up" data-aos-delay="1500">
        <img
          src={career}
          alt=""
          className="w-full h-[30vh] lg:h-[50vh] lg:w-[50vw]"
        />
      </div>
    </div>
  );
}

export default SectionOne;
