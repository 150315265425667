import React, { useEffect, useState } from "react";
import s from "../../Assets/Images/save.svg";
import c from "../../Assets/Images/cart.svg";
import { Link, useParams } from "react-router-dom";
import { useProducts } from "../../Context/ProductsContext";

function Cards() {
  const { id } = useParams();
  const { getProductsSubBrands, isProductsSubBrands } = useProducts();

  useEffect(() => {
    getProductsSubBrands(id);
  }, []);

  const [hoveredSubBrandId, setHoveredSubBrandId] = useState(null);

  return (
    <div
      className="grid grid-rows-3 grid-cols-1 lg:grid-cols-4 gap-5 w-full justify-items-center"
      dir="ltr"
    >
      {isProductsSubBrands?.data?.map((subBrand) => (
        <div
          key={subBrand.id}
          className="relative"
          onMouseEnter={() => setHoveredSubBrandId(subBrand.id)}
          onMouseLeave={() => setHoveredSubBrandId(null)}
        >
          <img
            src={subBrand.image_url}
            alt=""
            className="w-[300px] h-[300px]"
          />
          {hoveredSubBrandId === subBrand.id && (
            <div className="absolute inset-0 grid place-items-center bg-[#00000080]">
              <div className="absolute bottom-0 left-0 right-0 flex flex-col gap-2 justify-end">
                <Link to={"/order"}>
                  <button className="bg-slate-950 text-[15px] lg:text-[15px] text-[#FFF] font-[Cairo] font-[600] py-1 px-5">
                    Ask your price
                  </button>
                </Link>
                <Link to={""}>
                  <button className="bg-slate-950 text-[15px] lg:text-[15px] text-[#FFF] font-[Cairo] font-[600] py-1 px-5">
                    Download
                  </button>
                </Link>
              </div>
            </div>
          )}
        </div>
      ))}
    </div>
  );
}

export default Cards;
