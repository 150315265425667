import React from "react";
import pr1 from "../../Assets/Images/pr1.jpeg";
import pr2 from "../../Assets/Images/pr2.jpeg";
import pr3 from "../../Assets/Images/pr3.jpeg";
import pr4 from "../../Assets/Images/pr4.jpeg";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
function Cards() {
  const { t } = useTranslation();
  const products = [
    { id: 1, image: pr1, title: "WOODEN CHAIR" },
    { id: 2, image: pr2, title: "FLOOR LAMP" },
    { id: 3, image: pr3, title: "ROHE CHAIR" },
    { id: 4, image: pr4, title: "BLACK LAMP" },
    { id: 1, image: pr1, title: "WOODEN CHAIR" },
    { id: 2, image: pr2, title: "FLOOR LAMP" },
    { id: 3, image: pr3, title: "ROHE CHAIR" },
    { id: 4, image: pr4, title: "BLACK LAMP" },
    { id: 1, image: pr1, title: "WOODEN CHAIR" },
    { id: 2, image: pr2, title: "FLOOR LAMP" },
    { id: 3, image: pr3, title: "ROHE CHAIR" },
    // { id: 4, image: pr4, title: "BLACK LAMP" },
  ];

  return (
    <div className="flex flex-col gap-12 my-10 justify-center w-[100%] ">
      <div className="flex flex-col items-start gap-3">
        <label
          htmlFor="category"
          className="text-[#141414] text-[15px] lg:text-[20px] font-[Cairo] font-[600]"
        >
          {t("Category")}
        </label>
        <select
          id="category"
          className="border-[1px] border-[#DFEAF2] py-1 text-[15px] lg:text-[20px] w-[20rem] px-2"
          style={{ color: "#5F5F5F" }}
        >
          <option>All Categories</option>
          <option>All Categories</option>
          <option>All Categories</option>
        </select>
      </div>
      {/* flex flex-col lg:flex-wrap lg:flex-row lg:justify-around gap-10  items-center */}
      <div className="grid grid-rows-3 grid-cols-1 lg:grid-cols-4 gap-5">
        {products.map((product) => (
          <Link to={"/Product"} key={product.id}>
            <div className="flex flex-col gap-5 items-start cursor-pointer">
              <img
                src={product.image}
                alt=""
                className="w-[350px] h-[210px] lg:w-[350px] lg:h-[350px]"
              />
              <h3 className="text-[#141414] text-[20px] lg:text-[32px] font-[Cairo] font-[400]">
                {product.title}
              </h3>
            </div>
          </Link>
        ))}
      </div>
    </div>
  );
}

export default Cards;
