import React from "react";
import { useTranslation } from "react-i18next";

function Cards({ isContact }) {
  const { t } = useTranslation();

  return (
    <div className="flex flex-col lg:flex-row justify-center gap-10 my-10 w-full">
      <div className="flex flex-col  shadow-custom border-t-[10px] border-[#0A66B0] px-6 py-10 lg:w-[30vw]">
        <div className="flex flex-col items-start gap-3 ">
          <h1 className="text-[#000] text-[18px] lg:text-[32px] text-start font-[Cairo] font-[600]">
            {isContact?.[0].name}
          </h1>
          <h3 className="text-[#141414] text-[16px] lg:text-[24px] text-start font-[Cairo] font-[400]">
            {isContact?.[0].address}
          </h3>
          <h3 className="text-[#141414] text-[16px] lg:text-[24px] text-start font-[Cairo] font-[400]">
            {isContact?.[0].tel}
          </h3>
          <h3 className="text-[#141414] text-[16px] lg:text-[24px] text-start font-[Cairo] font-[400]">
            {isContact?.[0].mail}
          </h3>
        </div>
        <button className="bg-[#0A66B0] text-[#FFF] text-[14px] lg:text-[20px] font-[Cairo] font-[400] block mx-auto mt-14 mb-5 py-1 px-10 rounded-full">
          <a href={isContact?.[0].directions} target="_blank" rel="noopener noreferrer">
            {t("GetDirections")}
          </a>
        </button>
      </div>
      <div className="flex flex-col  shadow-custom border-t-[10px] border-[#AF1F24] px-6 py-10 lg:w-[30vw]">
        <div className="flex flex-col items-start gap-3 ">
          <h1 className="text-[#000] text-start text-[18px] lg:text-[32px] font-[Cairo] font-[600]">
            {isContact?.[1].name}
          </h1>
          <h3 className="text-[#141414] text-start text-[16px] lg:text-[24px] font-[Cairo] font-[400]">
            {isContact?.[1].address}
          </h3>
          <h3 className="text-[#141414] text-start text-[16px] lg:text-[24px] font-[Cairo] font-[400]">
            {isContact?.[1].tel}
          </h3>
          <h3 className="text-[#141414] text-start text-[16px] lg:text-[24px] font-[Cairo] font-[400]">
            {isContact?.[1].mail}
          </h3>
        </div>
        <button className="bg-[#AF1F24] text-[#FFF] text-[14px] lg:text-[20px] font-[Cairo] font-[400] block mx-auto mt-14 mb-5 py-1 px-10 rounded-full">
          <a href={isContact?.[1].directions} target="_blank" rel="noopener noreferrer">
            {t("GetDirections")}
          </a>
        </button>
      </div>
    </div>
  );
}

export default Cards;
